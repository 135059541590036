<template>
    <div class="HeaderMobile" :class="{'HeaderMobile--subpage': isSubpage}">
        <div class="HeaderMobile__container">
            <div class="HeaderMobile__main-content" @click="handleBack">
                <div v-if="isSubpage" class="HeaderMobile__back">
                    <img v-lazy-load src="@/assets/img/icons/header-mobile-back.svg" alt="Back">
                </div>
                <div class="HeaderMobile__title">
                    {{ title }}
                </div>
            </div>
            <div v-if="$slots.action" class="HeaderMobile__action">
                <slot name="action"/>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "HeaderMobile",
    props: {
        title: {
            type: String,
            required: true,
        },
        back: {
            type: Boolean,
            default: false,
        },
        backLink: [Object, String],
    },
    computed: {
        isSubpage() {
            return !!this.back;
        },
    },
    methods: {
        handleBack() {
            if (this.back) {
                if (this.backLink) {
                    this.$router.push(this.backLink);
                } else {
                    if (this.$store.state.prevRoute) {
                        this.$router.back();
                    } else {
                        this.$router.push('/');
                    }
                }
            }
        },
    },
}
</script>

<style lang="scss">
.HeaderMobile {
    position: sticky;
    top: 0;

    z-index: 100;

    padding: 0 $container-padding-mobile;
    background: $white;

    &--subpage & {
        &__container {
            border-bottom: 1px solid rgb($blackBlue, 0.16);
        }

        &__title {
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
        }

        &__main-content {
            cursor: pointer;
        }
    }

    &__container {
        display: flex;
        justify-content: space-between;
        align-items: center;

        min-height: 50px;
        padding: 8px 0;
    }

    &__main-content {
        display: flex;
        align-items: center;
    }

    &__back {

    }

    &__title {
        font-weight: 700;
        font-size: 18px;
        line-height: 26px;
        letter-spacing: -0.01em;
        color: $blackBlue;
    }

    &__action {
        display: inline-flex;
        margin-left: 12px;
    }
}
</style>
