var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ClientOnly',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.$media.md),expression:"$media.md"}],staticClass:"MainNavTabBar",class:{'MainNavTabBar--hide': _vm.scrollHide}},[_c('div',{staticClass:"MainNavTabBar__list"},_vm._l((_vm.navsList),function(ref,index){
var title = ref.title;
var icon = ref.icon;
var link = ref.link;
var exact = ref.exact;
var notification = ref.notification;
var source_name = ref.source_name;
return _c('MainNavTabBarItem',{key:index,staticClass:"MainNavTabBar__item",attrs:{"title":title,"icon":icon,"link":link,"exact":exact,"notification":notification},nativeOn:{"click":function($event){return _vm.setSourceSignup(source_name)}}})}),1),_vm._v(" "),_vm._t("default",null,{"hidden":_vm.scrollHide})],2)])}
var staticRenderFns = []

export { render, staticRenderFns }