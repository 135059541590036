<template>
    <div
        class="selections-join-block-primary"
        :class="{accent}"
    >
        <div class="container">
            <div class="content" v-lazy-background :lazy-background="joinIcon">
                <h3>Присоединяйтесь к&nbsp;Rentride</h3>
                <p>
                    Зарегистрируйтесь и начните <br class="mobile-accent"> пользоваться сервисом,<br class="hide-mobile">
                    как владелец <br class="mobile-accent"> авто или арендатор.
                    <span class="wishes">
                        Удачи на дорогах
                        <img v-lazy-load class="smile" src="@/assets/img/icons/smile-with-hearts.png" alt="smile">
                    </span>
                </p>
                <div class="actions">
                    <router-link
                        class="btn"
                        :class="accent ? 'white' : 'primary'"
                        :to="{ name: 'sdat-city', params: { city: $store.state.city }}"
                        @click.native.capture="$signals.sdatButton"
                    >
                        Сдать автомобиль
                    </router-link>
                    <router-to-cars
                        :city="$route.params.city"
                        class="btn secondary"
                        @click.native="$signals.ctaJoinUsClickRentCar"
                    >
                        {{ accent ? 'Арендовать' : 'Арендовать автомобиль' }}
                    </router-to-cars>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import RouterToCars from "@/components/router/routerToCars";
import { getFormatImg } from '@/utils/browser/getFormatImg';

export default {
    name: "joinPrimary",
    components: { RouterToCars },
    props: {
        accent: Boolean,
    },
    computed: {
        joinIcon() {
            if (!this.accent) {
                return this.$img('/img/layouts/join.png', { format: getFormatImg(this.$store) });
            }
        },
    },
}
</script>

<style lang="scss">
.selections-join-block-primary {
    position: relative;

    margin-bottom: 40px;
    padding-top: 40px;

    @include mobile {
        margin-bottom: 24px;
        padding-top: 16px;
    }

    .mobile-accent {
        display: none;
    }

    &.accent {
        padding-top: 16px;
        margin-bottom: 50px;

        @include mobile {
            overflow: hidden;

            padding-top: 12px;
            margin: 0 $negative-container-padding-mobile;

            .mobile-accent {
                display: block;
            }
        }

        .content {
            margin: 0 $negative-container-padding;
            padding: 32px 60px;

            background: #5394FD;

            @include mobile {
                padding: 24px 16px;
                margin: 0 $negative-container-padding-mobile;

                border-radius: 0;
            }
        }

        h3 {
            margin-bottom: 10px;

            font-weight: 700;
            font-size: 40px;
            line-height: 48px;
            text-align: center;
            letter-spacing: -0.02em;
            color: #FFFFFF;

            @include mobile {
                max-width: 300px;
                margin-bottom: 8px;

                text-align: left;
                font-size: 24px;
                line-height: 32px;
                letter-spacing: -0.01em;
            }
        }

        p {
            margin-bottom: 28px;

            font-weight: 400;
            font-size: 18px;
            line-height: 26px;
            text-align: center;
            letter-spacing: -0.01em;
            color: #FFFFFF;

            @include mobile {
                margin-bottom: 16px;

                text-align: left;
                font-size: 16px;
                line-height: 24px;
            }

            .wishes img {
                position: absolute;
                top: 70px;
                right: -4px;

                display: block;
                width: 40px;
                height: 40px;
                margin: 0;

                @include mobile {
                    transform: scaleX(-1) rotate(8deg);

                    width: 64px;
                    height: 64px;

                    top: 32px;
                    right: -14px;
                }
            }
        }

        .actions {
            display: flex;
            justify-content: center;

            @include mobile {
                flex-direction: column;
            }

            button,
            .btn {
                @include mobileUp {
                    min-width: 236px;
                }
            }

            .secondary {
                background: rgba(#FFFFFF, 0.24);
                color: #FFFFFF;
            }
        }
    }

    .content {
        padding: 16px;

        background: $white no-repeat center right;
        background-size: auto auto;
        border-radius: 12px;

        @include mobile {
            background-image: none !important;
        }

        @include mobileUp {
            padding: 32px 473px 32px 32px;
            margin: 0;
        }
    }

    h3 {
        margin-bottom: 8px;

        font-weight: bold;
        font-size: 24px;
        line-height: 32px;
        color: $blackBlue;

        @include mobileUp {
            margin-bottom: 12px;

            font-size: 40px;
            line-height: 48px;
        }
    }

    p {
        margin-bottom: 16px;

        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        color: rgba($blackBlue, 0.64);

        @include mobileUp {
            margin-bottom: 32px;

            font-size: 20px;
            line-height: 28px;
        }

        .wishes {
            display: inline-flex;
            align-items: center;

            .smile {
                display: flex;
                width: 32px;
                height: 32px;
                margin-left: 8px;

                transform: rotate(8deg);

                @include mobile {
                    display: none;
                }
            }
        }
    }

    .actions {
        display: block;

        .btn {
            height: 60px;
            border-radius: 10px;

            @include mobile {
                height: 46px;
                width: 100%;
                font-weight: normal;
                font-size: 16px;
                line-height: 24px;

                border-radius: 8px;

                &:not(:last-child) {
                    margin-bottom: 8px;
                }
            }
        }
    }

    @include mobileUp {
        .actions {
            display: flex;
            justify-content: flex-start;

            .btn {
                padding: 16px 32px;
                font-size: 18px;

                &:not(:last-child) {
                    margin-right: 16px;
                }
            }
        }
    }
}
</style>
