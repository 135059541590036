export default {
    data: () => ({
        scrollHide: false,
    }),
    computed: {
        scrollHideOffset() {
            return 60;
        },
        scrollHandler() {
            let prevScrollPosition = 0;
            let lastShowedUpScrollPosition = 0;

            return () => {
                const scrollPosition = window.scrollY;

                if (lastShowedUpScrollPosition === 0) {
                    lastShowedUpScrollPosition = prevScrollPosition;
                }

                if (scrollPosition < prevScrollPosition) {
                    this.scrollHide = false;
                    lastShowedUpScrollPosition = scrollPosition;
                } else if (lastShowedUpScrollPosition + this.scrollHideOffset < scrollPosition) {
                    this.scrollHide = true;
                }
                prevScrollPosition = scrollPosition;
            }
        },
    },
    created() {
        if (process.client) {
            window.addEventListener('scroll', this.scrollHandler, {passive: true});
        }
    },
    beforeDestroy() {
        if (process.client) {
            window.removeEventListener('scroll', this.scrollHandler)
        }
    }
}
