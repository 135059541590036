<template>
    <Modal
        class="PaymentBonusPointsInfoModal"
        title="Бонусные баллы RentRide"
        :id="$modals.PAYMENT_BONUS_POINTS_INFO"
        :short="$media.md"
    >
        <template #default>
            После завершения аренды на ваш бонусный счет начисляется 1% от стоимости аренды. Начисленные баллы действуют год с момента активации, если иное не указано в условиях акции.
            <br><br>
            Узнать, сколько у вас баллов можно в личном кабинете.<br>
            1 балл = 1 рубль.
            <br><br>
            Баллы можно тратить на будущие поездки оплачивая до 15% стоимости аренды (без учета доп. услуг). Чтобы списать баллы, при оформлении аренды выберите «Потратить баллы».
            <br><br>
            <a href="/docs/bonus_program_rules.pdf" target="_blank">
                Правила Бонусной программы
            </a>
        </template>
        <template #footer="{close}">
            <button class="primary" @click="close">
                Понятно
            </button>
        </template>
    </Modal>
</template>

<script>
import Modal from "@/components/_elements/modal";
import modal from "@/mixins/modal/modal";

export default {
    name: "PaymentBonusPointsInfoModal",
    mixins: [modal],
    components: {Modal},
}
</script>

<style lang="scss">
.PaymentBonusPointsInfoModal {

    a {
        color: #307EFD;
        cursor: pointer;

        &:hover {
            color: #307EFD;
        }
    }
}
</style>
