<template>
    <ClientOnly>
        <div
            v-show="$media.md"
            class="MainNavTabBar"
            :class="{'MainNavTabBar--hide': scrollHide}"
        >
            <div class="MainNavTabBar__list">
                <MainNavTabBarItem
                    v-for="({title, icon, link, exact, notification, source_name}, index) in navsList"
                    :key="index"
                    class="MainNavTabBar__item"
                    :title="title"
                    :icon="icon"
                    :link="link"
                    :exact="exact"
                    :notification="notification"
                    @click.native="setSourceSignup(source_name)"
                />
            </div>
            <slot :hidden="scrollHide"/>
        </div>
    </ClientOnly>
</template>

<script>
import MainNavTabBarItem from "@/components/nav/tabBar/MainNavTabBarItem";
import SearchIcon from 'assets/img/icons/nav/tab-bar/search.svg?raw';
import DealsIcon from 'assets/img/icons/nav/tab-bar/deals.svg?raw';
import CarsIcon from 'assets/img/icons/nav/tab-bar/cars.svg?raw';
import ChatIcon from 'assets/img/icons/nav/tab-bar/chat.svg?raw';
import ProfileIcon from 'assets/img/icons/nav/tab-bar/profile.svg?raw';
import {getRouteToCars} from "@/utils/router";
import scrollDownShow from "@/mixins/position/scrollDownShow";
import {mapMutations, mapState} from "vuex";
import {SIGN_UP_SOURCE} from "@/utils/_consts";
import profileExpiringBonusPoints from "@/mixins/profile/profileExpiringBonusPoints";


export default {
    name: "MainNavTabBar",
    components: {MainNavTabBarItem},
    mixins: [scrollDownShow, profileExpiringBonusPoints],
    computed: {
        navsList() {
            return [
                {
                    title: 'Поиск',
                    icon: SearchIcon,
                    link: getRouteToCars(),
                },
                {
                    title: 'Аренды',
                    icon: DealsIcon,
                    link: {name: 'profile-reservations'},
                    source_name: SIGN_UP_SOURCE.TAB_MY_CARS
                },
                {
                    title: 'Мои авто',
                    icon: CarsIcon,
                    link: {name: 'profile-my-cars'},
                    source_name: SIGN_UP_SOURCE.TAB_MY_CARS
                },
                {
                    title: 'Входящие',
                    icon: ChatIcon,
                    link: {name: 'profile-chat-dealId'},
                    notification: !!this.profileUnreadMessagesCount,
                    source_name: SIGN_UP_SOURCE.TAB_MESSENGER,
                },
                {
                    title: 'Профиль',
                    icon: this.profileNavItemIcon,
                    exact: false,
                    link: {name: 'profile-navigation'},
                    notification: this.profileExpiringBonusPoints.length,
                    source_name: SIGN_UP_SOURCE.TAB_PROFILE,
                },
            ];
        },
        profileNavItemIcon() {
            if (this.$auth.loggedIn) {
                const avatar = this.$auth.user.avatar;
                if (avatar) {
                    const style = `background-image: url('${avatar}')`
                    return (
                        `<div class="MainNavTabBar__avatar" style="${style}"/>`
                    )
                }
            }
            return ProfileIcon;
        },
        ...mapState('profile', ['profileUnreadMessagesCount']),
    },
    methods: {
        ...mapMutations(['setSourceSignup']),
    }
}
</script>

<style lang="scss">
.MainNavTabBar {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 75;

    background: $white;
    box-shadow: 0 3px 20px -2px rgba(11, 33, 65, 0.12), 0px 0 0px rgba($black, 0.16);

    transition: transform 250ms;

    &--hide {
        transform: translateY(100%);
    }

    &__list {
        display: flex;
        align-items: stretch;
        justify-content: space-between;
    }

    &__item {
        flex-grow: 1;
    }

    &__avatar {
        width: 24px;
        height: 24px;
        border-radius: 50px;

        background-position: center;
        background-size: cover;
        background-color: rgba($blackBlue, 0.4);
    }
}
</style>
