<template>
    <RouterLink
        :to="link"
        class="MainNavTabBarItem"
        :class="{'MainNavTabBarItem--notification': notification}"
        :[activeClassAttr]="'MainNavTabBarItem--active'"
        @click.native="handleClick"
    >
        <div class="MainNavTabBarItem__icon" v-html="icon"/>
        <div class="MainNavTabBarItem__title">
            {{title}}
        </div>
    </RouterLink>
</template>

<script>
export default {
    name: "MainNavTabBarItem",
    props: {
        title: String,
        icon: String,
        link: [String, Object],
        exact: Boolean,
        notification: Boolean,
    },
    computed: {
        activeClassAttr() {
            return this.exact ? 'exact-active-class' : 'active-class';
        },
    },
    methods: {
        handleClick() {
            this.$signals.tabNavigationClick(this.title);
        },
    },
}
</script>

<style lang="scss">
.MainNavTabBarItem {
    display: flex;
    flex-direction: column;
    align-items: center;

    padding: 8px 8px 6px;

    cursor: pointer;

    &:hover {
        text-decoration: none;
    }

    &--active &,
    &:hover & {
        &__icon path {
            fill-opacity: 1;
            fill: #5394FD;
        }

        &__title {
            color: #5394FD;
        }
    }

    &--notification & {
        &__icon::after {
            content: '';
            position: absolute;
            top: 0;
            right: -2px;

            width: 10px;
            height: 10px;

            border-radius: 50%;
            background: #F14B3C;
        }
    }

    &__icon {
        position: relative;
        display: flex;
        margin-bottom: 4px;
    }

    &__title {
        font-weight: 500;
        font-size: 10px;
        line-height: 14px;
        text-align: center;
        color: rgba($blackBlue, 0.4);
    }
}
</style>
