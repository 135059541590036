<template>
    <div
        class="BaseLayout"
        :class="{
            'BaseLayout--hide-footer-mobile': isHideFooterMobile,
            'BaseLayout--fix-padding-tab-bar': isHideFooterMobile && showTabBar,
         }"
    >
        <HeaderMobile
            v-if="showMobileHeader"
            :title="title"
            :back="subpage"
            :back-link="backLink"
        >
            <template #action>
                <slot name="header-action"/>
            </template>
        </HeaderMobile>
        <Header
            v-else
            :class="{'hide-mobile': hideHeaderMobile}"
            :white="!transparentHeader"
        />
        <slot>
            <Nuxt/>
        </slot>
        <JoinPrimary v-if="showJoinBlock"/>
        <slot name="bottom"/>
        <Footer/>
        <MainNavTabBar
            v-if="showTabBar"
            #default="props"
        >
            <slot name="tab-bar" v-bind="props"/>
        </MainNavTabBar>
        <PaymentBonusPointsInfoModal/>
    </div>
</template>

<script>
import Header from "@/components/_layout/Header";
import HeaderMobile from "@/components/_layout/HeaderMobile";
import Footer from "@/components/_layout/Footer";
import MainNavTabBar from "@/components/nav/tabBar/MainNavTabBar";
import JoinPrimary from "@/components/cars/joinPrimary";
import PaymentBonusPointsInfoModal from "@/components/modals/PaymentBonusPointsInfoModal";

export default {
    name: "BaseLayout",
    components: { PaymentBonusPointsInfoModal, Header, HeaderMobile, Footer, MainNavTabBar, JoinPrimary },
    props: {
        subpage: Boolean,
        hideTabBar: Boolean,
        backLink: [Object, String],
        title: String,
        transparentHeader: Boolean,
        showJoinBlock: Boolean,
        hideHeaderMobile: Boolean,
        showFooterMobile: Boolean,
        hideFooterMobile: Boolean,
    },
    computed: {
        showTabBar() {
            return !this.hideTabBar && !this.subpage;
        },
        showMobileHeader() {
            return !!this.title && process.client && this.$media.md;
        },
        routeName() {
            return this.$route?.name;
        },
        isHideFooterMobile() {
            return this.hideFooterMobile || (this.subpage && !this.showFooterMobile);
        },
    },
}
</script>

<style lang="scss">
.BaseLayout {
    @include mobile {
        &--hide-footer-mobile {

            footer {
                display: none;
            }
        }

        &--fix-padding-tab-bar {
            padding-bottom: calc(56px + 8px + env(safe-area-inset-bottom, 0px));
        }
    }
}
</style>
